<template>

  <div class="">
    <p>
      为深入实施创新驱动发展战略，强化科技自立自强的战略支撑，加快推进重大科研基础设施与大型科研仪器向社会开放共享，优化粤港澳大湾区基础研究与创新创业环境，根据《中华人民共和国科学技术进步法》《国务院关于国家重大科研基础设施和大型科研仪器向社会开放的意见》（国发〔2014〕70号）《广东省自主创新促进条例》《广东省科技创新“十四五”规划》等文件精神，将非涉密的重大科研设施与大型科研仪器等信息纳入统一平台并进行开放共享。
    </p>
    <p>
      完全或部分利用政府资金投入建设和购置（以下简称财政资金购置）的单台套价值在30万元的科研设施与仪器原则上都应当纳入省共享平台向社会开放共享，为用户提供服务，尤其要为创新创业、中小微企业发展提供支撑保障。法律法规另有特殊规定的除外。
    </p>
    <p>
      对于完全利用社会资金投入建设和购置（以下简称社会购置）的科研设施与仪器，以及价值在30万元以下财政资金购置的科学仪器设备，由管理单位自愿申报，纳入省共享网。
    </p>
    <p>
      科研设施与仪器所依托管理的法人单位应把科研设施与仪器纳入省共享平台统一管理，公布科研设施与仪器目录、开放共享管理制度、服务方式、服务内容、服务流程、预约时间、联系方式、收费标准等信息。
    </p>
    <img style="width:100%;"  src="@static/img/baosongliucheng.png"/>
<!--    <a style="color: #0000EE;text-decoration: underline;"  href="https://www.gdkjzy.net/newsDetail?id=1684404836025503745">[用户手册]科研仪器填报指引及API对接接口文档</a>-->
      <el-link type="primary" href="/help" target="_blank" style="font-size: larger;"><b><i class="el-icon-warning-outline"></i>《数据汇交导入指引》</b></el-link>

      <div style="display: flex;justify-content: center;padding: 10px;">
          <button type="button" class="btn btn-primary btn-huijiao" @click="gotoNext"> 汇交入口</button>
      </div>
  </div>
</template>

<script>
import CommonUtil from "@/utils/common"
export default {
    name: "Platform",
    data() {
        return {

        }
    },
    setup() {

    },
    components: {},
    mounted() {


    },
    watch: {

    },
    methods: {

        async gotoNext() {
            // return this.$upgrading();

            var userInfo = this.$store.state.userInfo;
            if (userInfo && userInfo.userCode) {
                let isPlatformAdmin = await CommonUtil.isPlatformAdmin();

                if (isPlatformAdmin) {
                    let goPageurl = this.indexUrl + "/user/dataRes";
                    window.open(goPageurl);
                } else {
                    this.ElMessage.error('您没有权限，只有平台负责人才能进行汇交。');
                }
            } else {
                this.ElMessage.error("请先登录");

            }


        },

    }
}
</script>

<style lang="less" scoped>


</style>

