<template>

  <div class="">
    <p>
      围绕我省战略性新兴产业、支柱产业及未来产业的发展需求， 发挥知识、数据、信息、技术、人才等要素资源优势，为平台建设、人才引进、科研攻关、成果转化提供精准服务，打通全过程创新生态链：
    </p>
    <div>
      <p>✔科技资源服务：科研仪器、生物种质、科学数据、实验动物</p>
      <p>✔研发咨询服务：科研与技术方向诊断、先进技术引进与产学研合作、科技管理咨询、知识产权布局</p>
      <p>✔科技发展规划：行业数据统计分析、政策趋势研究、科技平台发展规划、科技情报统计分析</p>
      <p>✔科技成果服务：关键技术需求分析、科技成果评价、适用技术成果挖掘、科技成果推广</p>
    </div>
    <img style="width:100%;" src="@static/img/huijiao14.png"/>
    <p>
      服务案例：
    </p>
    <img style="width:100%;" src="@static/img/huijiao15.png"/>
    <br/>
    <br/>
    <br/>
  </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>


</style>

