<template>
    <div class="">
        对财政资金购置的大型科学仪器进行查重评议，健全仪器设备购置论证和技术共享机制，减少重复购置，促进开放共享，提高财政资金使用效率。
        <img style="width:100%;" src="@static/img/huijiao13.png"/>
        国家根据科学技术进步的需要，按照统筹规划、突出共享、优化配置、综合集成、政府主导、多方共建的原则，统筹购置大型科学仪器、设备，并开展对以财政性资金为主购置的大型科学仪器、设备的联合评议工作。
        ——中华人民共和国科学技术进步法 第九十四条
        <div style="display: flex;justify-content: center;padding: 10px;">
            <button type="button" class="btn btn-primary btn-huijiao" @click="apply">申请查重</button>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'

export default {

    setup() {



    },
    methods: {
        apply() {
            var userInfo = this.$store.state.userInfo;
            if (userInfo && userInfo.userCode) {
                openDialog("showDetail", "申请查重", "/gdsti2v/resource/resReviewDuplication/form", "", '95%', '95%', function () {

                });
            } else {
                HFAlert("请先登录！");
                // this.ElMessage.error("请先登录！");

            }
        },
    }

}
</script>

<style lang="less" scoped>


</style>

